import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import Container from '../../components/container'
import CloudIndexNav from '../../components/cloudIndexNav/cloudIndexNav'
import CloudIndexTable from '../../components/cloudIndexTable/cloudIndexTable'
import { Accordion } from '../../components/accordion/accordion'

import infraLogo from '../../img/infrared100logo.png'

import onepassword from '../../img/logos/onepassword-logo/onepassword-logo.png'
import onepasswordHover from '../../img/logos/onepassword-logo/onepassword-logo-hover.png'
import abnormal from '../../img/logos/abnormal-logo/abnormal-logo.png'
import abnormalHover from '../../img/logos/abnormal-logo/abnormal-logo-hover.png'
import airplane from '../../img/logos/airplane-logo/airplane-logo.png'
import airplaneHover from '../../img/logos/airplane-logo/airplane-logo-hover.png'
import appomni from '../../img/logos/appomni-logo/appomni-logo.png'
import appomniHover from '../../img/logos/appomni-logo/appomni-logo-hover.png'
import arcticwolf from '../../img/logos/arcticwolf-logo/arcticwolf-logo.png'
import arcticwolfHover from '../../img/logos/arcticwolf-logo/arcticwolf-logo-hover.png'
import assemblyai from '../../img/logos/assemblyai-logo/assemblyai-logo.png'
import assemblyaiHover from '../../img/logos/assemblyai-logo/assemblyai-logo-hover.png'
import atlan from '../../img/logos/atlan-logo/atlan-logo.png'
import atlanHover from '../../img/logos/atlan-logo/atlan-logo-hover.png'
import autogpt from '../../img/logos/autogpt-logo/autogpt-logo.png'
import autogptHover from '../../img/logos/autogpt-logo/autogpt-logo-hover.png'
import axonius from '../../img/logos/axonius-logo/axonius-logo.png'
import axoniusHover from '../../img/logos/axonius-logo/axonius-logo-hover.png'
import buildbuddy from '../../img/logos/buildbuddy-logo/buildbuddy-logo.png'
import buildbuddyHover from '../../img/logos/buildbuddy-logo/buildbuddy-logo-hover.png'
import chainguard from '../../img/logos/chainguard-logo/chainguard-logo.png'
import chainguardHover from '../../img/logos/chainguard-logo/chainguard-logo-hover.png'
import chromatic from '../../img/logos/chromatic-logo/chromatic-logo.png'
import chromaticHover from '../../img/logos/chromatic-logo/chromatic-logo-hover.png'
import chronosphere from '../../img/logos/chronosphere-logo/chronosphere-logo.png'
import chronosphereHover from '../../img/logos/chronosphere-logo/chronosphere-logo-hover.png'
import clickhouse from '../../img/logos/clickhouse-logo/clickhouse-logo.png'
import clickhouseHover from '../../img/logos/clickhouse-logo/clickhouse-logo-hover.png'
import cockroachlabs from '../../img/logos/cockroachlabs-logo/cockroachlabs-logo.png'
import cockroachlabsHover from '../../img/logos/cockroachlabs-logo/cockroachlabs-logo-hover.png'
import collibra from '../../img/logos/collibra-logo/collibra-logo.png'
import collibraHover from '../../img/logos/collibra-logo/collibra-logo-hover.png'
import conductorone from '../../img/logos/conductorone-logo/conductorone-logo.png'
import conductoroneHover from '../../img/logos/conductorone-logo/conductorone-logo-hover.png'
import cortex from '../../img/logos/cortex-logo/cortex-logo.png'
import cortexHover from '../../img/logos/cortex-logo/cortex-logo-hover.png'
import cribl from '../../img/logos/cribl-logo/cribl-logo.png'
import criblHover from '../../img/logos/cribl-logo/cribl-logo-hover.png'
import cyberhaven from '../../img/logos/cyberhaven-logo/cyberhaven-logo.png'
import cyberhavenHover from '../../img/logos/cyberhaven-logo/cyberhaven-logo-hover.png'
import cyera from '../../img/logos/cyera-logo/cyera-logo.png'
import cyeraHover from '../../img/logos/cyera-logo/cyera-logo-hover.png'
import dagger from '../../img/logos/dagger-logo/dagger-logo.png'
import daggerHover from '../../img/logos/dagger-logo/dagger-logo-hover.png'
import databricks from '../../img/logos/databricks-logo/databricks-logo.png'
import databricksHover from '../../img/logos/databricks-logo/databricks-logo-hover.png'
import dataiku from '../../img/logos/dataiku-logo/dataiku-logo.png'
import dataikuHover from '../../img/logos/dataiku-logo/dataiku-logo-hover.png'
import dbtlabs from '../../img/logos/dbtlabs-logo/dbtlabs-logo.png'
import dbtlabsHover from '../../img/logos/dbtlabs-logo/dbtlabs-logo-hover.png'
import deno from '../../img/logos/deno-logo/deno-logo.png'
import denoHover from '../../img/logos/deno-logo/deno-logo-hover.png'
import descope from '../../img/logos/descope-logo/descope-logo.png'
import descopeHover from '../../img/logos/descope-logo/descope-logo-hover.png'
import docker from '../../img/logos/docker-logo/docker-logo.png'
import dockerHover from '../../img/logos/docker-logo/docker-logo-hover.png'
import doppler from '../../img/logos/doppler-logo/doppler-logo.png'
import dopplerHover from '../../img/logos/doppler-logo/doppler-logo-hover.png'
import dragonflydb from '../../img/logos/dragonflydb-logo/dragonflydb-logo.png'
import dragonflydbHover from '../../img/logos/dragonflydb-logo/dragonflydb-logo-hover.png'
import drata from '../../img/logos/drata-logo/drata-logo.png'
import drataHover from '../../img/logos/drata-logo/drata-logo-hover.png'
import dremio from '../../img/logos/dremio-logo/dremio-logo.png'
import dremioHover from '../../img/logos/dremio-logo/dremio-logo-hover.png'
import fireblocks from '../../img/logos/fireblocks-logo/fireblocks-logo.png'
import fireblocksHover from '../../img/logos/fireblocks-logo/fireblocks-logo-hover.png'
import fivetran from '../../img/logos/fivetran-logo/fivetran-logo.png'
import fivetranHover from '../../img/logos/fivetran-logo/fivetran-logo-hover.png'
import flyio from '../../img/logos/flyio-logo/flyio-logo.png'
import flyioHover from '../../img/logos/flyio-logo/flyio-logo-hover.png'
import glean from '../../img/logos/glean-logo/glean-logo.png'
import gleanHover from '../../img/logos/glean-logo/glean-logo-hover.png'
import grafanalabs from '../../img/logos/grafanalabs-logo/grafanalabs-logo.png'
import grafanalabsHover from '../../img/logos/grafanalabs-logo/grafanalabs-logo-hover.png'
import harness from '../../img/logos/harness-logo/harness-logo.png'
import harnessHover from '../../img/logos/harness-logo/harness-logo-hover.png'
import hex from '../../img/logos/hex-logo/hex-logo.png'
import hexHover from '../../img/logos/hex-logo/hex-logo-hover.png'
import hightouch from '../../img/logos/hightouch-logo/hightouch-logo.png'
import hightouchHover from '../../img/logos/hightouch-logo/hightouch-logo-hover.png'
import huggingface from '../../img/logos/huggingface-logo/huggingface-logo.png'
import huggingfaceHover from '../../img/logos/huggingface-logo/huggingface-logo-hover.png'
import humansignal from '../../img/logos/humansignal-logo/humansignal-logo.png'
import humansignalHover from '../../img/logos/humansignal-logo/humansignal-logo-hover.png'
import incidentio from '../../img/logos/incidentio-logo/incidentio-logo.png'
import incidentioHover from '../../img/logos/incidentio-logo/incidentio-logo-hover.png'
import island from '../../img/logos/island-logo/island-logo.png'
import islandHover from '../../img/logos/island-logo/island-logo-hover.png'
import isovalent from '../../img/logos/isovalent-logo/isovalent-logo.png'
import isovalentHover from '../../img/logos/isovalent-logo/isovalent-logo-hover.png'
import jellyfish from '../../img/logos/jellyfish-logo/jellyfish-logo.png'
import jellyfishHover from '../../img/logos/jellyfish-logo/jellyfish-logo-hover.png'
import kubecost from '../../img/logos/kubecost-logo/kubecost-logo.png'
import kubecostHover from '../../img/logos/kubecost-logo/kubecost-logo-hover.png'
import langchain from '../../img/logos/langchain-logo/langchain-logo.png'
import langchainHover from '../../img/logos/langchain-logo/langchain-logo-hover.png'
import launchdarkly from '../../img/logos/launchdarkly-logo/launchdarkly-logo.png'
import launchdarklyHover from '../../img/logos/launchdarkly-logo/launchdarkly-logo-hover.png'
import linear from '../../img/logos/linear-logo/linear-logo.png'
import linearHover from '../../img/logos/linear-logo/linear-logo-hover.png'
import livekit from '../../img/logos/livekit-logo/livekit-logo.png'
import livekitHover from '../../img/logos/livekit-logo/livekit-logo-hover.png'
import lumos from '../../img/logos/lumos-logo/lumos-logo.png'
import lumosHover from '../../img/logos/lumos-logo/lumos-logo-hover.png'
import materialize from '../../img/logos/materialize-logo/materialize-logo.png'
import materializeHover from '../../img/logos/materialize-logo/materialize-logo-hover.png'
import metronome from '../../img/logos/metronome-logo/metronome-logo.png'
import metronomeHover from '../../img/logos/metronome-logo/metronome-logo-hover.png'
import minio from '../../img/logos/minio-logo/minio-logo.png'
import minioHover from '../../img/logos/minio-logo/minio-logo-hover.png'
import modal from '../../img/logos/modal-logo/modal-logo.png'
import modalHover from '../../img/logos/modal-logo/modal-logo-hover.png'
import montecarlo from '../../img/logos/montecarlo-logo/montecarlo-logo.png'
import montecarloHover from '../../img/logos/montecarlo-logo/montecarlo-logo-hover.png'
import motherduck from '../../img/logos/motherduck-logo/motherduck-logo.png'
import motherduckHover from '../../img/logos/motherduck-logo/motherduck-logo-hover.png'
import neon from '../../img/logos/neon-logo/neon-logo.png'
import neonHover from '../../img/logos/neon-logo/neon-logo-hover.png'
import omni from '../../img/logos/omni-logo/omni-logo.png'
import omniHover from '../../img/logos/omni-logo/omni-logo-hover.png'
import openai from '../../img/logos/openai-logo/openai-logo.png'
import openaiHover from '../../img/logos/openai-logo/openai-logo-hover.png'
import orcasecurity from '../../img/logos/orcasecurity-logo/orcasecurity-logo.png'
import orcasecurityHover from '../../img/logos/orcasecurity-logo/orcasecurity-logo-hover.png'
import oven from '../../img/logos/oven-logo/oven-logo.png'
import ovenHover from '../../img/logos/oven-logo/oven-logo-hover.png'
import pinecone from '../../img/logos/pinecone-logo/pinecone-logo.png'
import pineconeHover from '../../img/logos/pinecone-logo/pinecone-logo-hover.png'
import planetscale from '../../img/logos/planetscale-logo/planetscale-logo.png'
import planetscaleHover from '../../img/logos/planetscale-logo/planetscale-logo-hover.png'
import postman from '../../img/logos/postman-logo/postman-logo.png'
import postmanHover from '../../img/logos/postman-logo/postman-logo-hover.png'
import projectdiscoveryio from '../../img/logos/projectdiscoveryio-logo/projectdiscoveryio-logo.png'
import projectdiscoveryioHover from '../../img/logos/projectdiscoveryio-logo/projectdiscoveryio-logo-hover.png'
import pulumi from '../../img/logos/pulumi-logo/pulumi-logo.png'
import pulumiHover from '../../img/logos/pulumi-logo/pulumi-logo-hover.png'
import railway from '../../img/logos/railway-logo/railway-logo.png'
import railwayHover from '../../img/logos/railway-logo/railway-logo-hover.png'
import redis from '../../img/logos/redis-logo/redis-logo.png'
import redisHover from '../../img/logos/redis-logo/redis-logo-hover.png'
import redpandadata from '../../img/logos/redpandadata-logo/redpandadata-logo.png'
import redpandadataHover from '../../img/logos/redpandadata-logo/redpandadata-logo-hover.png'
import replicate from '../../img/logos/replicate-logo/replicate-logo.png'
import replicateHover from '../../img/logos/replicate-logo/replicate-logo-hover.png'
import replicated from '../../img/logos/replicated-logo/replicated-logo.png'
import replicatedHover from '../../img/logos/replicated-logo/replicated-logo-hover.png'
import replit from '../../img/logos/replit-logo/replit-logo.png'
import replitHover from '../../img/logos/replit-logo/replit-logo-hover.png'
import retool from '../../img/logos/retool-logo/retool-logo.png'
import retoolHover from '../../img/logos/retool-logo/retool-logo-hover.png'
import runzero from '../../img/logos/runzero-logo/runzero-logo.png'
import runzeroHover from '../../img/logos/runzero-logo/runzero-logo-hover.png'
import scaleai from '../../img/logos/scaleai-logo/scaleai-logo.png'
import scaleaiHover from '../../img/logos/scaleai-logo/scaleai-logo-hover.png'
import semgrep from '../../img/logos/semgrep-logo/semgrep-logo.png'
import semgrepHover from '../../img/logos/semgrep-logo/semgrep-logo-hover.png'
import sigma from '../../img/logos/sigma-logo/sigma-logo.png'
import sigmaHover from '../../img/logos/sigma-logo/sigma-logo-hover.png'
import singlestore from '../../img/logos/singlestore-logo/singlestore-logo.png'
import singlestoreHover from '../../img/logos/singlestore-logo/singlestore-logo-hover.png'
import snyk from '../../img/logos/snyk-logo/snyk-logo.png'
import snykHover from '../../img/logos/snyk-logo/snyk-logo-hover.png'
import soloio from '../../img/logos/soloio-logo/soloio-logo.png'
import soloioHover from '../../img/logos/soloio-logo/soloio-logo-hover.png'
import sourcegraph from '../../img/logos/sourcegraph-logo/sourcegraph-logo.png'
import sourcegraphHover from '../../img/logos/sourcegraph-logo/sourcegraph-logo-hover.png'
import starburst from '../../img/logos/starburst-logo/starburst-logo.png'
import starburstHover from '../../img/logos/starburst-logo/starburst-logo-hover.png'
import startree from '../../img/logos/startree-logo/startree-logo.png'
import startreeHover from '../../img/logos/startree-logo/startree-logo-hover.png'
import stripe from '../../img/logos/stripe-logo/stripe-logo.png'
import stripeHover from '../../img/logos/stripe-logo/stripe-logo-hover.png'
import supabase from '../../img/logos/supabase-logo/supabase-logo.png'
import supabaseHover from '../../img/logos/supabase-logo/supabase-logo-hover.png'
import synthesia from '../../img/logos/synthesia-logo/synthesia-logo.png'
import synthesiaHover from '../../img/logos/synthesia-logo/synthesia-logo-hover.png'
import tabular from '../../img/logos/tabular-logo/tabular-logo.png'
import tabularHover from '../../img/logos/tabular-logo/tabular-logo-hover.png'
import tackleio from '../../img/logos/tackleio-logo/tackleio-logo.png'
import tackleioHover from '../../img/logos/tackleio-logo/tackleio-logo-hover.png'
import tailscale from '../../img/logos/tailscale-logo/tailscale-logo.png'
import tailscaleHover from '../../img/logos/tailscale-logo/tailscale-logo-hover.png'
import temporal from '../../img/logos/temporal-logo/temporal-logo.png'
import temporalHover from '../../img/logos/temporal-logo/temporal-logo-hover.png'
import timescale from '../../img/logos/timescale-logo/timescale-logo-v2.png'
import timescaleHover from '../../img/logos/timescale-logo/timescale-logo-hover-v2.png'
import tines from '../../img/logos/tines-logo/tines-logo.png'
import tinesHover from '../../img/logos/tines-logo/tines-logo-hover.png'
import vercel from '../../img/logos/vercel-logo/vercel-logo.png'
import vercelHover from '../../img/logos/vercel-logo/vercel-logo-hover.png'
import weaviate from '../../img/logos/weaviate-logo/weaviate-logo.png'
import weaviateHover from '../../img/logos/weaviate-logo/weaviate-logo-hover.png'
import weightsbiases from '../../img/logos/weightsbiases-logo/weightsbiases-logo.png'
import weightsbiasesHover from '../../img/logos/weightsbiases-logo/weightsbiases-logo-hover.png'
import wiz from '../../img/logos/wiz-logo/wiz-logo.png'
import wizHover from '../../img/logos/wiz-logo/wiz-logo-hover.png'
import workato from '../../img/logos/workato-logo/workato-logo.png'
import workatoHover from '../../img/logos/workato-logo/workato-logo-hover.png'
import zesty from '../../img/logos/zesty-logo/zesty-logo.png'
import zestyHover from '../../img/logos/zesty-logo/zesty-logo-hover.png'
import straiker from '../../img/logos/straiker-logo/straiker-logo.png'
import straikerHover from '../../img/logos/straiker-logo/straiker-logo-hover.png'

// new logos
import anthropic from '../../img/logos/anthropic-logo/anthropic-logo.png'
import anthropicHover from '../../img/logos/anthropic-logo/anthropic-logo-hover.png'
import baseten from '../../img/logos/baseten-logo/baseten-logo.png'
import basetenHover from '../../img/logos/baseten-logo/baseten-logo-hover.png'
import bitdrift from '../../img/logos/bitdrift-logo/bitdrift-logo.png'
import bitdriftHover from '../../img/logos/bitdrift-logo/bitdrift-logo-hover.png'
import braintrust from '../../img/logos/braintrust-logo/braintrust-logo.png'
import braintrustHover from '../../img/logos/braintrust-logo/braintrust-logo-hover.png'
import cerby from '../../img/logos/cerby-logo/cerby-logo.png'
import cerbyHover from '../../img/logos/cerby-logo/cerby-logo-hover.png'
import clerk from '../../img/logos/clerk-logo/clerk-logo.png'
import clerkHover from '../../img/logos/clerk-logo/clerk-logo-hover.png'
import codium from '../../img/logos/codium-logo/codium-logo.png'
import codiumHover from '../../img/logos/codium-logo/codium-logo-hover.png'
import cognition from '../../img/logos/cognition-logo/cognition-logo.png'
import cognitionHover from '../../img/logos/cognition-logo/cognition-logo-hover.png'
import coreweave from '../../img/logos/coreweave-logo/coreweave-logo.png'
import coreweaveHover from '../../img/logos/coreweave-logo/coreweave-logo-hover.png'
import iielevenlabs from '../../img/logos/iielevenlabs-logo/iielevenlabs-logo.png'
import iielevenlabsHover from '../../img/logos/iielevenlabs-logo/iielevenlabs-logo-hover.png'
import flutterflow from '../../img/logos/flutterflow-logo/flutterflow-logo.png'
import flutterflowHover from '../../img/logos/flutterflow-logo/flutterflow-logo-hover.png'
import lancedb from '../../img/logos/lancedb-logo/lancedb-logo.png'
import lancedbHover from '../../img/logos/lancedb-logo/lancedb-logo-hover.png'
import llamaindex from '../../img/logos/llamaindex-logo/llamaindex-logo.png'
import llamaindexHover from '../../img/logos/llamaindex-logo/llamaindex-logo-hover.png'
import localstack from '../../img/logos/localstack-logo/localstack-logo.png'
import localstackHover from '../../img/logos/localstack-logo/localstack-logo-hover.png'
import magic from '../../img/logos/magic-logo/magic-logo.png'
import magicHover from '../../img/logos/magic-logo/magic-logo-hover.png'
import mistralai from '../../img/logos/mistralai-logo/mistralai-logo.png'
import mistralaiHover from '../../img/logos/mistralai-logo/mistralai-logo-hover.png'
import modular from '../../img/logos/modular-logo/modular-logo.png'
import modularHover from '../../img/logos/modular-logo/modular-logo-hover.png'
import momento from '../../img/logos/momento-logo/momento-logo.png'
import momentoHover from '../../img/logos/momento-logo/momento-logo-hover.png'
import nagomi from '../../img/logos/nagomi-logo/nagomi-logo.png'
import nagomiHover from '../../img/logos/nagomi-logo/nagomi-logo-hover.png'
import normai from '../../img/logos/normai-logo/normai-logo.png'
import normaiHover from '../../img/logos/normai-logo/normai-logo-hover.png'
import oasis from '../../img/logos/oasis-logo/oasis-logo.png'
import oasisHover from '../../img/logos/oasis-logo/oasis-logo-hover.png'
import ollama from '../../img/logos/ollama-logo/ollama-logo.png'
import ollamaHover from '../../img/logos/ollama-logo/ollama-logo-hover.png'
import patronusai from '../../img/logos/patronusai-logo/patronusai-logo.png'
import patronusaiHover from '../../img/logos/patronusai-logo/patronusai-logo-hover.png'
import poolside from '../../img/logos/poolside-logo/poolside-logo.png'
import poolsideHover from '../../img/logos/poolside-logo/poolside-logo-hover.png'
import reflex from '../../img/logos/reflex-logo/reflex-logo.png'
import reflexHover from '../../img/logos/reflex-logo/reflex-logo-hover.png'
import resend from '../../img/logos/resend-logo/resend-logo.png'
import resendHover from '../../img/logos/resend-logo/resend-logo-hover.png'
import restate from '../../img/logos/restate-logo/restate-logo.png'
import restateHover from '../../img/logos/restate-logo/restate-logo-hover.png'
import sublimesecurity from '../../img/logos/sublimesecurity-logo/sublimesecurity-logo.png'
import sublimesecurityHover from '../../img/logos/sublimesecurity-logo/sublimesecurity-logo-hover.png'
import surrealdb from '../../img/logos/surrealdb-logo/surrealdb-logo.png'
import surrealdbHover from '../../img/logos/surrealdb-logo/surrealdb-logo-hover.png'
import tessell from '../../img/logos/tessell-logo/tessell-logo.png'
import tessellHover from '../../img/logos/tessell-logo/tessell-logo-hover.png'
import tigerbeetle from '../../img/logos/tigerbeetle-logo/tigerbeetle-logo.png'
import tigerbeetleHover from '../../img/logos/tigerbeetle-logo/tigerbeetle-logo-hover.png'
import togetherai from '../../img/logos/togetherai-logo/togetherai-logo.png'
import togetheraiHover from '../../img/logos/togetherai-logo/togetherai-logo-hover.png'
import upwind from '../../img/logos/upwind-logo/upwind-logo.png'
import upwindHover from '../../img/logos/upwind-logo/upwind-logo-hover.png'
import vantage from '../../img/logos/vantage-logo/vantage-logo.png'
import vantageHover from '../../img/logos/vantage-logo/vantage-logo-hover.png'
import vast from '../../img/logos/vast-logo/vast-logo.png'
import vastHover from '../../img/logos/vast-logo/vast-logo-hover.png'
import warpstream from '../../img/logos/warpstream-logo/warpstream-logo.png'
import warpstreamHover from '../../img/logos/warpstream-logo/warpstream-logo-hover.png'
import zedindustries from '../../img/logos/zedindustries-logo/zedindustries-logo.png'
import zedindustriesHover from '../../img/logos/zedindustries-logo/zedindustries-logo-hover.png'

import report from '../../img/infrared-report.png'

import * as styles from './infra.module.css'
import linkStyle from '../../styles/components/links.module.css'
import Icon from '../../components/icons'

export const query = graphql`
  query CloudInfraredQuery {
      page: sanityPage(title: { eq: "Infrared" }) {
          title
          _rawBody
          metaTitle
          metaDescription
          metaKeywords
          metaImage {
              asset {
                  _id
                  url
              }
          }
          infrared {
              documents {
                  year
                  googleDriveLink
              }
          }
      }
      infrared: allGoogle2024InfraRed100TableSheet {
          nodes {
              company
              logoRef: logo
              url
              stage
              location
              totalRaisedToDate
              subcategory
              portfolio
          }
      }
    infraredOld: allGoogleInfraRed100TableSheet {
        nodes {
          company
          logoRef: logo
          url
          stage
          location
          totalFunding
          subcategory
          portfolio
        }
    }
  }
`

const CloudInfraPage = props => {
  const { data, errors } = props
  const logos = {
    onepassword: onepassword,
    onepasswordHover: onepasswordHover,
    abnormal: abnormal,
    abnormalHover: abnormalHover,
    airplane: airplane,
    airplaneHover: airplaneHover,
    appomni: appomni,
    appomniHover: appomniHover,
    arcticwolf: arcticwolf,
    arcticwolfHover: arcticwolfHover,
    assemblyai: assemblyai,
    assemblyaiHover: assemblyaiHover,
    atlan: atlan,
    atlanHover: atlanHover,
    autogpt: autogpt,
    autogptHover: autogptHover,
    axonius: axonius,
    axoniusHover: axoniusHover,
    buildbuddy: buildbuddy,
    buildbuddyHover: buildbuddyHover,
    chainguard: chainguard,
    chainguardHover: chainguardHover,
    chromatic: chromatic,
    chromaticHover: chromaticHover,
    chronosphere: chronosphere,
    chronosphereHover: chronosphereHover,
    clickhouse: clickhouse,
    clickhouseHover: clickhouseHover,
    cockroachlabs: cockroachlabs,
    cockroachlabsHover: cockroachlabsHover,
    collibra: collibra,
    collibraHover: collibraHover,
    conductorone: conductorone,
    conductoroneHover: conductoroneHover,
    cortex: cortex,
    cortexHover: cortexHover,
    cribl: cribl,
    criblHover: criblHover,
    cyberhaven: cyberhaven,
    cyberhavenHover: cyberhavenHover,
    cyera: cyera,
    cyeraHover: cyeraHover,
    dagger: dagger,
    daggerHover: daggerHover,
    databricks: databricks,
    databricksHover: databricksHover,
    dataiku: dataiku,
    dataikuHover: dataikuHover,
    dbtlabs: dbtlabs,
    dbtlabsHover: dbtlabsHover,
    deno: deno,
    denoHover: denoHover,
    descope: descope,
    descopeHover: descopeHover,
    docker: docker,
    dockerHover: dockerHover,
    doppler: doppler,
    dopplerHover: dopplerHover,
    dragonflydb: dragonflydb,
    dragonflydbHover: dragonflydbHover,
    drata: drata,
    drataHover: drataHover,
    dremio: dremio,
    dremioHover: dremioHover,
    fireblocks: fireblocks,
    fireblocksHover: fireblocksHover,
    fivetran: fivetran,
    fivetranHover: fivetranHover,
    flyio: flyio,
    flyioHover: flyioHover,
    glean: glean,
    gleanHover: gleanHover,
    grafanalabs: grafanalabs,
    grafanalabsHover: grafanalabsHover,
    harness: harness,
    harnessHover: harnessHover,
    hex: hex,
    hexHover: hexHover,
    hightouch: hightouch,
    hightouchHover: hightouchHover,
    huggingface: huggingface,
    huggingfaceHover: huggingfaceHover,
    humansignal: humansignal,
    humansignalHover: humansignalHover,
    incidentio: incidentio,
    incidentioHover: incidentioHover,
    island: island,
    islandHover: islandHover,
    isovalent: isovalent,
    isovalentHover: isovalentHover,
    jellyfish: jellyfish,
    jellyfishHover: jellyfishHover,
    kubecost: kubecost,
    kubecostHover: kubecostHover,
    langchain: langchain,
    langchainHover: langchainHover,
    launchdarkly: launchdarkly,
    launchdarklyHover: launchdarklyHover,
    linear: linear,
    linearHover: linearHover,
    livekit: livekit,
    livekitHover: livekitHover,
    lumos: lumos,
    lumosHover: lumosHover,
    materialize: materialize,
    materializeHover: materializeHover,
    metronome: metronome,
    metronomeHover: metronomeHover,
    minio: minio,
    minioHover: minioHover,
    modal: modal,
    modalHover: modalHover,
    montecarlo: montecarlo,
    montecarloHover: montecarloHover,
    motherduck: motherduck,
    motherduckHover: motherduckHover,
    neon: neon,
    neonHover: neonHover,
    omni: omni,
    omniHover: omniHover,
    openai: openai,
    openaiHover: openaiHover,
    orcasecurity: orcasecurity,
    orcasecurityHover: orcasecurityHover,
    oven: oven,
    ovenHover: ovenHover,
    pinecone: pinecone,
    pineconeHover: pineconeHover,
    planetscale: planetscale,
    planetscaleHover: planetscaleHover,
    postman: postman,
    postmanHover: postmanHover,
    projectdiscoveryio: projectdiscoveryio,
    projectdiscoveryioHover: projectdiscoveryioHover,
    pulumi: pulumi,
    pulumiHover: pulumiHover,
    railway: railway,
    railwayHover: railwayHover,
    redis: redis,
    redisHover: redisHover,
    redpandadata: redpandadata,
    redpandadataHover: redpandadataHover,
    replicate: replicate,
    replicateHover: replicateHover,
    replicated: replicated,
    replicatedHover: replicatedHover,
    replit: replit,
    replitHover: replitHover,
    retool: retool,
    retoolHover: retoolHover,
    runzero: runzero,
    runzeroHover: runzeroHover,
    scaleai: scaleai,
    scaleaiHover: scaleaiHover,
    semgrep: semgrep,
    semgrepHover: semgrepHover,
    sigma: sigma,
    sigmaHover: sigmaHover,
    singlestore: singlestore,
    singlestoreHover: singlestoreHover,
    snyk: snyk,
    snykHover: snykHover,
    soloio: soloio,
    soloioHover: soloioHover,
    sourcegraph: sourcegraph,
    sourcegraphHover: sourcegraphHover,
    starburst: starburst,
    starburstHover: starburstHover,
    startree: startree,
    startreeHover: startreeHover,
    stripe: stripe,
    stripeHover: stripeHover,
    supabase: supabase,
    supabaseHover: supabaseHover,
    synthesia: synthesia,
    synthesiaHover: synthesiaHover,
    tabular: tabular,
    tabularHover: tabularHover,
    tackleio: tackleio,
    tackleioHover: tackleioHover,
    tailscale: tailscale,
    tailscaleHover: tailscaleHover,
    temporal: temporal,
    temporalHover: temporalHover,
    timescale: timescale,
    timescaleHover: timescaleHover,
    tines: tines,
    tinesHover: tinesHover,
    vercel: vercel,
    vercelHover: vercelHover,
    weaviate: weaviate,
    weaviateHover: weaviateHover,
    weightsbiases: weightsbiases,
    weightsbiasesHover: weightsbiasesHover,
    wiz: wiz,
    wizHover: wizHover,
    workato: workato,
    workatoHover: workatoHover,
    zesty: zesty,
    zestyHover: zestyHover,
    anthropic: anthropic,
    anthropicHover: anthropicHover,
    baseten: baseten,
    basetenHover: basetenHover,
    bitdrift: bitdrift,
    bitdriftHover: bitdriftHover,
    braintrustdata: braintrust,
    braintrustdataHover: braintrustHover,
    cerby: cerby,
    cerbyHover: cerbyHover,
    clerk: clerk,
    clerkHover: clerkHover,
    codium: codium,
    codiumHover: codiumHover,
    cognition: cognition,
    cognitionHover: cognitionHover,
    coreweave: coreweave,
    coreweaveHover: coreweaveHover,
    elevenlabs: iielevenlabs,
    elevenlabsHover: iielevenlabsHover,
    flutterflow: flutterflow,
    flutterflowHover: flutterflowHover,
    lancedb: lancedb,
    lancedbHover: lancedbHover,
    llamaindex: llamaindex,
    llamaindexHover: llamaindexHover,
    localstack: localstack,
    localstackHover: localstackHover,
    magic: magic,
    magicHover: magicHover,
    mistral: mistralai,
    mistralHover: mistralaiHover,
    modular: modular,
    modularHover: modularHover,
    momento: momento,
    momentoHover: momentoHover,
    nagomisecurity: nagomi,
    nagomisecurityHover: nagomiHover,
    normai: normai,
    normaiHover: normaiHover,
    oasis: oasis,
    oasisHover: oasisHover,
    ollama: ollama,
    ollamaHover: ollamaHover,
    patronus: patronusai,
    patronusHover: patronusaiHover,
    'poolside ': poolside,
    'poolside Hover': poolsideHover,
    'reflex ': reflex,
    'reflex Hover': reflexHover,
    resend: resend,
    resendHover: resendHover,
    'restate ': restate,
    'restate Hover': restateHover,
    sublimesecurity: sublimesecurity,
    sublimesecurityHover: sublimesecurityHover,
    surrealdb: surrealdb,
    surrealdbHover: surrealdbHover,
    tessell: tessell,
    tessellHover: tessellHover,
    tigerbeetle: tigerbeetle,
    tigerbeetleHover: tigerbeetleHover,
    'togetherai ': togetherai,
    'togetherai Hover': togetheraiHover,
    upwind: upwind,
    upwindHover: upwindHover,
    vantage: vantage,
    vantageHover: vantageHover,
    vastdata: vast,
    vastdataHover: vastHover,
    warpstream: warpstream,
    warpstreamHover: warpstreamHover,
    zed: zedindustries,
    zedHover: zedindustriesHover,
    straiker: straiker,
    straikerHover: straikerHover
  }

  const defaultNodes = data.infrared.nodes.map(c => {
    const _c = { ...c }
    _c.logo = () => <><img src={logos[c.logoRef]} alt={c.company} className='default' /><img src={logos[`${c.logoRef}Hover`]} alt={c.company} className='hover' /></> || null

    return _c
  })

  const defaultOldNodes = data.infraredOld.nodes.map(c => {
    const _c = { ...c }
    _c.logo = () => <><img src={logos[c.logoRef]} alt={c.company} className='default' /><img src={logos[`${c.logoRef}Hover`]} alt={c.company} className='hover' /></> || null

    return _c
  })

  const [displayNodes, setDisplayNodes] = useState(defaultNodes)
  const [displayOldNodes, setDisplayOldNodes] = useState(defaultOldNodes)
  const [activeFilter, setActiveFilter] = useState('')

  const headers = [
    {
      label: 'Company',
      data: 'logo',
      format: 'component',
      orderby: true
    },
    {
      label: 'Stage',
      data: 'stage',
      orderby: true
    },
    {
      label: 'Location',
      data: 'location'
    },
    {
      label: 'Total Raised to Date',
      data: 'totalRaisedToDate',
      orderby: true
    },
    {
      label: 'Subcategory',
      data: 'subcategory'
    }
  ]

  const handleFilterClick = (stage) => {
    setActiveFilter(stage)
    if (stage !== '') {
      const filtered = defaultNodes.filter(n => n.stage === stage)
      const filteredOld = defaultOldNodes.filter(n => n.stage === stage)
      setDisplayNodes(filtered)
      setDisplayOldNodes(filteredOld)
    } else {
      setDisplayNodes(defaultNodes)
      setDisplayOldNodes(defaultOldNodes)
    }
  }

  const report2024 = data?.page?.infrared?.documents.find(report => report.year === '2024')

  function trackDownload (report) {
    if (!report) return
    if (typeof gtag !== 'undefined') {
      gtag('event', `download the report ${report}`, {
        component: 'button'
      })
    }
  }

  function convertToDownloadLink (viewLink) {
    const fileIdMatch = viewLink.match(/\/d\/([a-zA-Z0-9_-]+)\//)
    if (fileIdMatch && fileIdMatch[1]) {
      const fileId = fileIdMatch[1]
      return `https://drive.google.com/uc?export=download&id=${fileId}`
    } else {
      return viewLink
    }
  }

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  return (
    <Layout page='cloudInfrared' palette='black' hideContactBar version='1'>
      <SEO title='The InfraRed 100' description={data?.page?.metaDescription} keywords={data?.page?.metaKeywords ? data?.page?.metaKeywords.split(',') : []} imageFB={data?.page?.metaImage.asset.url} />

      <CloudIndexNav black current='infrared' />
      <Container class={styles.container}>
        <div className={styles.infra100header}>
          <div>
            <img src={infraLogo} alt='InfraRed' />
          </div>

          <div>
            InfraRed 100 honors 100 transformative companies in cloud infrastructure. These trailblazers have set new
            benchmarks for reliability, scalability, security, and innovation, enabling businesses to thrive in a cloud
            era and ushering a new paradigm of building applications. Every year, we plan to update this list and honor
            the latest companies that have excelled in building next generation cloud infrastructure solutions.
          </div>
        </div>

        <div className={styles.filterBy}>
          <div className={styles.label}>Filters</div>
          <div className={styles.filterWrapper}>
            <div>
              <span
                className={`${activeFilter === '' ? styles.active : ''}`}
                onClick={() => handleFilterClick('')}
              >All stages
              </span>
              {[...new Set(defaultNodes.map(n => n.stage).filter(n => n))].reverse().map((s, sI) =>
                <span
                  key={sI}
                  className={`${activeFilter === s ? styles.active : ''}`}
                  onClick={() => handleFilterClick(s)}
                >
                  {s}
                </span>
              )}
            </div>

            {report2024 && (
              <div style={{ display: 'flex' }}>
                <Link className={`${linkStyle.linkArrow} ${styles.downloadReportBtn} ${linkStyle.white}`} to={convertToDownloadLink(report2024.googleDriveLink)} onClick={() => trackDownload('2024')}>Download the {report2024.year} report
                  <span>
                    <div className={linkStyle.iconCircle} />
                    <div className={linkStyle.icon}>
                      <Icon symbol='newsArrowIcon' color='currentColor' />
                    </div>
                    <div className={linkStyle.iconSecondary}>
                      <Icon symbol='newsArrowIcon' color='currentColor' />
                    </div>
                  </span>
                </Link>
              </div>
            )}
          </div>
        </div>

        <CloudIndexTable headers={headers} data={displayNodes} inverse />

        <Accordion title='InfraRed100' marker='2023'>
          <CloudIndexTable headers={headers} data={displayOldNodes} inverse />
        </Accordion>

        <span className={`${styles.footnote} ${styles.inverse}`}>
          Early stage defined as Seed and Series A startups. Mid stage defined as startups that have raised at least a Series B with &lt;$200M total funding. Late stage defined as startups that have raised ≥$200m total funding. Total Funding Amounts from Pitchbook as of July 14th, 2023 and may include equity, debt, secondary or other financing transactions. For certain Redpoint portfolio companies (indicated by an asterisk), if Redpoint has access to additional information, a different Total Funding Amount may be listed. Redpoint selects the InfraRed 100 based on proprietary criteria, and certain companies selected (as indicated with an asterisk) are Redpoint portfolio companies.
        </span>
      </Container>
      {/* <CloudIndexResources withWave={true} theme='black' /> */}

    </Layout>
  )
}

export default CloudInfraPage
